<template>
  <div/>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Home',
  computed: {
    ...mapGetters([
      'isAppInitialized',
      'isLoggedIn',
    ]),
  },
  methods: {
    onAppInitialize() {
      const start = setInterval(() => {
        if (this.isAppInitialized) {
          clearInterval(start)
          if (this.isLoggedIn) {
            this.$router.push({ name: 'AllScreenings' })
            return
          }
          this.$router.push({ name: 'SignIn' })
        }
      }, 100)
    },
  },
  mounted() {
    this.onAppInitialize()
  },
}
</script>

<style lang="stylus" scoped>
</style>
